import React from "react";
import { useEffect, useState, useRef } from "react";
import { useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherDudesRendererArtifact from '../web3/abi/CypherdudesRenderer.json';
import CypherDudesBitRendererArtifact from '../web3/abi/CypherdudesBitRenderer.json';
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { ethers } from "ethers";
import Modal from 'react-modal';
import { GiChart, GiConsoleController, GiExpand } from "react-icons/gi";
import { BsLightningFill, BsRecordCircle, BsStopCircle } from "react-icons/bs";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import { FaFolderOpen } from "react-icons/fa6";
import { v4 as uuidv4 } from 'uuid';
import ChartRenderer from "../components/chartRenderer";
import InscriptionPreview from "../components/inscriptionPreview";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

import { bip39 } from "../App";

import Background from "../components/background";

const utilities = require("../components/utilities");
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const OwnerLink = styled(Link)`
margin-bottom :10px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const Cypherdude = ({ tokenID }) => {

    Modal.setAppElement('#root');

    const params = useParams();
    const id = params.tokenId;

    const [tokenURI, setTokenURI] = useState({});
    const [tokenName, setTokenName] = useState('');
    const [trackValues, setTrackValues] = useState(0);
    const [secretWord, setSecretWord] = useState('');
    const [decodeTicker, setDecodeTicker] = useState(false);
    const [encodeTicker, setEncodeTicker] = useState(false);
    const [lsbTicker, setLSBTicker] = useState(false);
    const [publicArchiveTicker, setPublicArchiveTicker] = useState(false);
    const [privateArchiveTicker, setPrivateArchiveTicker] = useState(false);
    const [txBeingSent, setTxBeingSent] = useState(undefined);
    const [txError, setTxError] = useState(undefined);
    const [updateResult, setUpdateResult] = useState('');
    const [currentOwner, setCurrentOwner] = useState({});
    const [supply, setSupply] = useState(0);
    const [dudeTokens, setDudeTokens] = useState([]);
    const [previewHash, setHash] = useState(null);
    const [previewMessage, setMess] = useState('');


    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider_ = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesRender,
        CypherDudesRendererArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesBitRendererReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBitRender,
        CypherDudesBitRendererArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        LlamaProvider_
    );

    const [txStatus, setTxStatus] = useState('idle');
    const [modalIsOpen, setIsOpen] = useState(false);
    const [archiveModalIsOpen, setArchiveIsOpen] = useState(false);
    const [privateArchiveModalIsOpen, setPrivateArchiveIsOpen] = useState(false);
    const [chartModalIsOpen, setChartModalIsOpen] = useState(false);
    const [previewModalIsOpen, setPreviewModalIsOpen] = useState(false);

    let subtitle;
    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    const customChartStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            innerWidth: '95%',
            innerHeight: '95%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    const customPreviewStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            innerWidth: '95%',
            innerHeight: '95%',
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        let navs = document.getElementsByTagName('nav');
        navs[0].style.display = "block";
    }, []);

    useEffect(() => {
        setTimeout(() => {
            _getTokenURI();
            getCurrentOwner(id);
        }, 500);

    }, []);

    useEffect(() => {
        setTimeout(() => {
            _getTokenURI();
            getCurrentOwner(id);
        }, 500);

    }, [id]);

    useEffect(() => {
        if (decodeTicker) {
            decode();
        }
        setDecodeTicker(false)
    }, [decodeTicker])

    useEffect(() => {
        if (encodeTicker) {
            encode();
        }
        setEncodeTicker(false)
    }, [encodeTicker])

    useEffect(() => {
        if (lsbTicker) {
            switchLSB();
        }
        setLSBTicker(false)
    }, [lsbTicker])

    useEffect(() => {
        if (publicArchiveTicker) {
            publicArchiveModal();
        }
        setPublicArchiveTicker(false)
    }, [publicArchiveTicker])

    useEffect(() => {
        if (privateArchiveTicker) {
            privateArchive();
        }
        setPrivateArchiveTicker(false)
    }, [privateArchiveTicker])

    const _dismissTransactionError = () => {
        setTxError(undefined);
    }

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);

    document.addEventListener("mousemove", function (event) {
        if(document.getElementById("desktopMenu") != null){
            const nav = document.getElementById("navMenu");
            nav.style.transform = "translateY(0)";
            nav.style.transition = "transform 0.3s ease-in-out";
        }
    })

    const getAddress = async (address) => {
        let resolvedAddress = await LlamaProvider_.lookupAddress(address);
        const holder = {
            longAddress: address,
            shortAddress: resolvedAddress === null ? address.slice(0, 6) + "..." + address.slice(-4) : resolvedAddress

        }
        return holder
    }

    const getCurrentOwner = async (id) => {
        try {
            let owner;
            if (id < 1728) {
                owner = await cypherDudesReadContract.ownerOf(id);
            } else {
                owner = await cypherDudesBitReadContract.ownerOf(id);
            }
            setCurrentOwner(await getAddress(owner));
        } catch (error) {
            console.log(error);
        }
    }

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    function openArchiveModal() {
        setArchiveIsOpen(true);
    }

    function afterOpenArchiveModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeArchiveModal() {
        setArchiveIsOpen(false);
    }

    function openPrivateArchiveModal() {
        setPrivateArchiveIsOpen(true);
    }

    function afterOpenPrivateArchiveModal() {
        subtitle.style.color = '#00ff00';
    }

    function closePrivateArchiveModal() {
        setPrivateArchiveIsOpen(false);
    }

    function openChartModal() {
        setChartModalIsOpen(true);
    }

    function afterOpenChartModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeChartModal() {
        setChartModalIsOpen(false);
    }

    function openPreviewModal() {
        setPreviewModalIsOpen(true);
    }

    function afterOpenPreviewModal() {
        subtitle.style.color = '#00ff00';
    }

    function closePreviewModal() {
        setPreviewModalIsOpen(false);
    }

    const getValueByTraitType = (jsonData, traitType) => jsonData.traits.find(trait => trait.trait_type === traitType)?.value || '';

    const _getTokenURI = async () => {
        if (!isConnected) {
            setTokenURI({});
            if (id < 1728) {
                try {
                    let tokenURI = await cypherDudesReadContract.tokenURI(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    let tokenData = await cypherDudesReadContract.tokenData(id);
                    const globalProgression = ethers.toNumber(tokenData[1]);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: id.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry);
                    setTokenName(tokenURIJSONDecoded.name);
                    setTrackValues(globalProgression);
                } catch (error) {
                    console.log(error)
                }
            } else {
                try {
                    let tokenURI = await cypherDudesBitReadContract.tokenURI(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    let tokenData = await cypherDudesBitReadContract.tokenData(id);
                    const globalProgression = ethers.toNumber(tokenData[1]);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: id.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry);
                    setTokenName(tokenURIJSONDecoded.name);
                    setTrackValues(globalProgression);
                } catch (error) {
                    console.log(error)
                }
            }

        } else {
            if (id < 1728) {
                try {
                    setTokenURI({});
                    const signer = await provider.getSigner();
                    const cypherDudesContract = new ethers.Contract(
                        contractAddresses.CypherDudes,
                        CypherDudesArtifact.abi,
                        signer
                    );
                    try {
                        let tokenURI = await cypherDudesContract.tokenURI(id);
                        const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                        const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                        const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                        let tokenData = await cypherDudesContract.tokenData(id);
                        const globalProgression = ethers.toNumber(tokenData[1]);
                        const tokenEntry = {
                            id: uuidv4(),
                            tokenId: id.toString(),
                            name: tokenURIJSONDecoded.name,
                            traits: tokenURIJSONDecoded.attributes,
                            animationURL: animationURL
                        }
                        setTokenURI(tokenEntry)
                        setTokenName(tokenURIJSONDecoded.name);
                        setTrackValues(globalProgression);
                    } catch (error) {
                        console.log(error)
                    }
                } catch (error) {
                    console.log(error);
                    setTxError(error);
                } finally {
                }
            } else {
                try {
                    setTokenURI({});
                    const signer = await provider.getSigner();
                    const cypherDudesBitContract = new ethers.Contract(
                        contractAddresses.CypherDudesBit,
                        CypherDudesBitArtifact.abi,
                        signer
                    );
                    try {
                        let tokenURI = await cypherDudesBitContract.tokenURI(id);
                        const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                        const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                        const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                        let tokenData = await cypherDudesBitContract.tokenData(id);
                        const globalProgression = ethers.toNumber(tokenData[1]);
                        const tokenEntry = {
                            id: uuidv4(),
                            tokenId: id.toString(),
                            name: tokenURIJSONDecoded.name,
                            traits: tokenURIJSONDecoded.attributes,
                            animationURL: animationURL
                        }
                        setTokenURI(tokenEntry)
                        setTokenName(tokenURIJSONDecoded.name);
                        setTrackValues(globalProgression);
                    } catch (error) {
                        console.log(error)
                    }
                } catch (error) {
                    console.log(error);
                    setTxError(error);
                } finally {
                }
            }

        }
    }

    function decode() {
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var pass = iframeDocument.getElementById('dKey').value;
        decrypt(pass);
    }

    async function encode() {
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var pass = iframeDocument.getElementById('eKey').value;
        var mess = iframeDocument.getElementById('mess').value;
        var hash = await encrypt(pass, mess);
        console.log(hash)
        setHash(hash);
        setMess(mess);
        openPreviewModal();
    }

    const encrypt = async (key, message) => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            if (id < 1728) {
                try {
                    const signer = await provider.getSigner();
                    const cypherDudesContract = new ethers.Contract(
                        contractAddresses.CypherDudes,
                        CypherDudesArtifact.abi,
                        signer
                    );
                    const hashed = await cypherDudesContract.encrypt(key, message);
                    const hashedBytes = utilities.stringToBytes(hashed)
                    return hashedBytes;
                } catch (error) {
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        return;
                    }
                    console.log(error);
                }
            } else {
                try {
                    const signer = await provider.getSigner();
                    const cypherDudesBitContract = new ethers.Contract(
                        contractAddresses.CypherDudesBit,
                        CypherDudesBitArtifact.abi,
                        signer
                    );
                    const hashed = await cypherDudesBitContract.encrypt(key, message);
                    const hashedBytes = utilities.stringToBytes(hashed)
                    return hashedBytes;
                } catch (error) {
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        return;
                    }
                    console.log(error);
                }
            }

        }
    }

    const inscribe = async () => {
        if (previewHash.length === 0) {
            return;
        }
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            if (id < 1728) {
                try {
                    closePreviewModal()
                    openModal();
                    const signer = await provider.getSigner();
                    const cypherDudesContract = new ethers.Contract(
                        contractAddresses.CypherDudes,
                        CypherDudesArtifact.abi,
                        signer
                    );
                    if (address === await cypherDudesContract.ownerOf(id)) {
                        const tx = await cypherDudesContract.writeCard(id, previewHash);
                        setTxBeingSent(tx.hash);
                        const receipt = await tx.wait();
                        if (receipt.status === 0) {
                            setTxStatus('inscription failed');
                            throw new Error("writing message failed");
                        }
                        setTxStatus('Message successfully inscribed on the blockchain');
                        var iframe = document.getElementById('tokenIframe');
                        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                        openModal();
                        iframeDocument.getElementById('eKey').value = '';
                        iframeDocument.getElementById('mess').value = '';
                    }
                } catch (error) {
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        setTxStatus('Inscription canceled by the user');
                        return;
                    }
                    const decodedError = cypherDudesReadContract.interface.parseError(error.data)
                    setTxStatus('Inscription failed : ' + decodedError?.args)
                    console.log(error);
                    setTxError(error);
                } finally {
                    setTxBeingSent(undefined);
                }
            } else {
                try {
                    openModal();
                    const signer = await provider.getSigner();
                    const cypherDudesBitContract = new ethers.Contract(
                        contractAddresses.CypherDudesBit,
                        CypherDudesBitArtifact.abi,
                        signer
                    );
                    if (address === await cypherDudesBitContract.ownerOf(id)) {
                        const tx = await cypherDudesBitContract.writeCard(id, previewHash);
                        setTxBeingSent(tx.hash);
                        const receipt = await tx.wait();
                        if (receipt.status === 0) {
                            setTxStatus('inscription failed');
                            throw new Error("writing message failed");
                        }
                        setTxStatus('Message successfully inscribed on the blockchain');
                        var iframe = document.getElementById('tokenIframe');
                        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                        openModal();
                        iframeDocument.getElementById('eKey').value = '';
                        iframeDocument.getElementById('mess').value = '';
                    }
                } catch (error) {
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        setTxStatus('Inscription canceled by the user');
                        return;
                    }
                    const decodedError = cypherDudesBitReadContract.interface.parseError(error.data)
                    setTxStatus('Inscription failed : ' + decodedError?.args)
                    console.log(error);
                    setTxError(error);
                } finally {
                    setTxBeingSent(undefined);
                }
            }

        }
    }

    const decrypt = async (key) => {
        if (id < 1728) {
            try {
                const hash = await cypherDudesReadContract.readCard(id);
                const message = await cypherDudesReadContract.decrypt(key, hash);
                var iframe = document.getElementById('tokenIframe');
                var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                iframeDocument.getElementById('decodedData').innerHTML = message;
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    return;
                }
                console.log(error);
                setTxError(error);
            }
        } else {
            try {
                const hash = await cypherDudesBitReadContract.readCard(id);
                const message = await cypherDudesBitReadContract.decrypt(key, hash);
                var iframe = document.getElementById('tokenIframe');
                var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                iframeDocument.getElementById('decodedData').innerHTML = message;
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    return;
                }
                console.log(error);
                setTxError(error);
            }
        }

    }

    const switchLSB = async () => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            if (id < 1728) {
                try {
                    openModal();
                    const signer = await provider.getSigner();
                    const cypherDudesRendererContract = new ethers.Contract(
                        contractAddresses.CypherDudesRender,
                        CypherDudesRendererArtifact.abi,
                        signer
                    );
                    let lsb = await cypherDudesRendererContract.lsb(id);
                    lsb = Boolean(lsb) ? 0 : 1;
                    const tx = await cypherDudesRendererContract.switchLSB(lsb, id);
                    setTxBeingSent(tx.hash);
                    const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('LSB switch failed');
                        throw new Error("LSB switch failed");
                    }
                    setTxStatus('LSB successfully switched');
                } catch (error) {
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        setTxStatus('LSB switch canceled by the user');
                        return;
                    }
                    const decodedError = cypherDudesRendererReadContract.interface.parseError(error.data)
                    setTxStatus('LSB switch failed : ' + decodedError?.args)
                    console.log(error);
                    setTxError(error);
                }
            } else {
                try {
                    openModal();
                    const signer = await provider.getSigner();
                    const cypherDudesBitRendererContract = new ethers.Contract(
                        contractAddresses.CypherDudesBitRender,
                        CypherDudesBitRendererArtifact.abi,
                        signer
                    );
                    let lsb = await cypherDudesBitRendererContract.lsb(id);
                    lsb = Boolean(lsb) ? 0 : 1;
                    const tx = await cypherDudesBitRendererContract.switchLSB(lsb, id);
                    setTxBeingSent(tx.hash);
                    const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('LSB switch failed');
                        throw new Error("LSB switch failed");
                    }
                    setTxStatus('LSB successfully switched');
                } catch (error) {
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        setTxStatus('LSB switch canceled by the user');
                        return;
                    }
                    const decodedError = cypherDudesBitRendererReadContract.interface.parseError(error.data)
                    setTxStatus('LSB switch failed : ' + decodedError?.args)
                    console.log(error);
                    setTxError(error);
                }
            }

        }
    }

    const publicArchive = async () => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                console.log('Public Archive setup ready');
                const signer = await provider.getSigner();
                const cypherDudesArchivesContract = new ethers.Contract(
                    contractAddresses.CypherDudesArchives,
                    CypherDudesArchivesArtifact.abi,
                    signer
                );
                const _inputSecretKey = document.getElementById("inputSecretKey").value;
                closeArchiveModal();
                openModal();
                const tx = await cypherDudesArchivesContract.mintPublicArchive(id, _inputSecretKey);
                const receipt = await tx.wait();
                if (receipt.status === 0) {
                    setTxStatus('Public Archive mint failed');
                    throw new Error("Public Archive mint failed");
                }
                setTxStatus('Public Archive successfully minted');
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Public Archive mint canceled by the user');
                    return;
                }
                const decodedError = cypherDudesArchivesReadContract.interface.parseError(error.data)
                setTxStatus('Public Archive mint failed : ' + decodedError?.args)
                console.log(error);
                setTxError(error);
            }
        }
    }

    const publicArchiveModal = async () => {
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            openArchiveModal();
        }
    }

    const privateArchive = async () => {
        closePrivateArchiveModal();
        if (!isConnected) {
            alert('connect your wallet');
            return;
        } else {
            try {
                console.log('Private Archive setup ready');
                const signer = await provider.getSigner();
                const cypherDudesArchivesContract = new ethers.Contract(
                    contractAddresses.CypherDudesArchives,
                    CypherDudesArchivesArtifact.abi,
                    signer
                );
                closePrivateArchiveModal();
                openModal();
                const tx = await cypherDudesArchivesContract.mintPrivateArchive(id);
                const receipt = await tx.wait();
                if (receipt.status === 0) {
                    setTxStatus('Private Archive mint failed');
                    throw new Error("Private Archive mint failed");
                }
                setTxStatus('Private Archive successfully minted');
            } catch (error) {
                if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                    setTxStatus('Private Archive mint canceled by the user');
                    return;
                }
                const decodedError = cypherDudesArchivesReadContract.interface.parseError(error.data)
                setTxStatus('Private Archive mint failed : ' + decodedError?.args)
                console.log(error);
                setTxError(error);
            }
        }
    }

    const reloadScripts = () => {
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var container = iframeDocument.getElementById('decodedData');
        let scriptList = container.getElementsByTagName('script');
        var newScripts = [];
        for (let i = 0; i < scriptList.length; i++) {
            let newScript = document.createElement('script');
            newScript.textContent = scriptList[i].textContent;
            scriptList[i].remove();
            newScripts.push(newScript);
        }
        newScripts.forEach((script) => container.lastChild.after(script))
    }

    const getDudeArchives = async () => {
        let fetchedArchives = [];
        try {
            setDudeTokens([]);
            const _supply = Number(await cypherDudesArchivesReadContract.totalSupply());
            setSupply(_supply);

            if (_supply === 0) {
                return;
            }
            for (let i = 0; i < _supply; i++) {
                try {
                    let archiveData = await cypherDudesArchivesReadContract.archiveData(i);
                    if (parseInt(id) === Number(archiveData[0])) {
                        const archiveEntry = {
                            archiveId: i,
                        }
                        fetchedArchives.push(archiveEntry);
                        setDudeTokens([...fetchedArchives]);
                    }
                } catch (error) {
                    console.log(error)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    window.addEventListener('decode', () => {
        setDecodeTicker(true)
    })

    window.addEventListener('encode', () => {
        setEncodeTicker(true)
    })

    window.addEventListener('lsb', () => {
        setLSBTicker(true);
    })

    window.addEventListener('publicArchive', () => {
        setPublicArchiveTicker(true);
    })

    window.addEventListener('privateArchive', () => {
        setPrivateArchiveTicker(true);
    })

    // window.addEventListener("keydown", function (e) {
    //     if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
    //         e.preventDefault();
    //     }
    // }, false);

    return (
        <div>
            <Background />
            <div className='previewContainer'>
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Inscription Complete">
                    <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>Transaction initiated...</h2>
                    <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
                </Modal>
                <Modal
                    isOpen={archiveModalIsOpen}
                    onAfterOpen={afterOpenArchiveModal}
                    onRequestClose={closeArchiveModal}
                    style={customStyles}
                    contentLabel="Public Archive Secret Key">
                    <div ref={(_subtitle) => (subtitle = _subtitle)}>
                        <p className="modalInfo">You're about to mint on the blockchain the content of the stored message as a PUBLIC ARCHIVE. Make sure that you're providing the <span style={{ textDecoration: "underline" }}>exact same</span> secret key that was used to encrypt the message.</p>
                        <input id="inputSecretKey" className="inputAddress" type="text" />
                        <button className="uxBtn" onClick={publicArchive}>MINT</button>
                    </div>
                    <button className="uxBtn modal" onClick={closeArchiveModal}>CANCEL</button>
                </Modal>
                <Modal
                    isOpen={privateArchiveModalIsOpen}
                    onAfterOpen={afterOpenPrivateArchiveModal}
                    onRequestClose={closePrivateArchiveModal}
                    style={customStyles}
                    contentLabel="Public Archive Secret Key">
                    <div ref={(_subtitle) => (subtitle = _subtitle)}>
                        <p className="modalInfo">You're about to mint on the blockchain the content of the stored message as a PRIVATE ARCHIVE. the message will be accessible only by entering the secret key.</p>
                        <button className="uxBtn" onClick={privateArchive}>MINT</button>
                    </div>
                    <button className="uxBtn modal" onClick={closePrivateArchiveModal}>CANCEL</button>
                </Modal>
                <Modal
                    isOpen={chartModalIsOpen}
                    onAfterOpen={afterOpenChartModal}
                    onRequestClose={closeChartModal}
                    style={customChartStyles}
                    contentLabel="Token Chart">
                    <div className="chartModal" ref={(_subtitle) => (subtitle = _subtitle)}>
                        <ChartRenderer trackValues={trackValues} tokenId={id} />
                        <p className="chartModalInfo">Chart showing the progression curve for the encoding capacity of this token.</p>
                        <p className="chartModalInfo">X scale is showing the tupple : Grid size - Grid resolution - Level Progression.</p>
                    </div>
                    <button className="uxBtn modal chartbtn" onClick={closeChartModal}>CLOSE</button>
                </Modal>
                <Modal
                    isOpen={previewModalIsOpen}
                    onAfterOpen={afterOpenPreviewModal}
                    onRequestClose={closePreviewModal}
                    style={customPreviewStyles}
                    contentLabel="Inscription Preview">
                    <div className="previewModal" ref={(_subtitle) => (subtitle = _subtitle)}>
                        <InscriptionPreview _previewMessage={previewMessage} />
                        <p className="chartModalInfo">This preview matches the archive format</p>
                    </div>
                    <div className="previewModalFooter">
                        <button className="uxBtn" onClick={closePreviewModal}>CANCEL</button>
                        <button className="uxBtn" onClick={inscribe}>INSCRIBE</button>
                    </div>

                </Modal>
                <div className='widePreview'>
                    <iframe id="tokenIframe" sandbox="allow-scripts allow-same-origin" frameBorder="0" srcDoc={tokenURI.animationURL}></iframe>
                </div>
                <div className='claimWordTokenInfo'>
                    <p>{tokenName}</p>
                    <div className="TokenBtns">
                        <Link to={`/cypherdude/${id==='0'?'2047':(parseInt(id)-1).toString()}`}  className='chartBtn'><AiOutlineCaretLeft /></Link>
                        <div>
                            <button onClick={openChartModal} className='chartBtn'><GiChart /></button>
                            <Link to={`/cypherdudeFS/${id}`} className='chartBtn'><GiExpand /></Link>
                            <button onClick={reloadScripts} className='chartBtn'><BsLightningFill /></button>
                            <Link to={`/folder/${id}`} className='chartBtn'><FaFolderOpen /></Link>
                        </div>
                        <Link to={`/cypherdude/${id==='2047'?'0':(parseInt(id)+1).toString()}`}  className='chartBtn'><AiOutlineCaretRight /></Link>
                    </div>
                    <div className="footerInfo">
                        <p id="issued" className='sideInfo'>{``}</p>
                        <p id="owned" className='sideInfo'>Owned by : <OwnerLink target="_blank" to={`https://zapper.xyz/account/${currentOwner.longAddress}`}>{currentOwner.shortAddress}</OwnerLink></p>
                    </div>
                </div>
            </div>
        </div>
    ) // <p id="issued" className='sideInfo'>{dudeTokens.length > 0 ? `This Cypherdude minted ${dudeTokens.length} Archive${dudeTokens.length > 0 ? 's' : ''}` : `No Archives minted yet`}</p>
}

export default Cypherdude;