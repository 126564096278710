import React from "react";
import LiveTokenCard from "../components/liveTokenCard";
import MediaQuery from 'react-responsive';
import { useEffect, useState } from "react";
import { ethers } from "ethers";
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherPalacesArtifact from '../web3/abi/CypherPalaces.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { v4 as uuidv4 } from 'uuid';
import { Alchemy, Network} from "alchemy-sdk";
import { breakpoints } from "../components/constants";
import { useParams } from 'react-router-dom';
import { alchemySettings } from "../App";
import { ReactComponent as Loader } from '../components/svg/loader.svg';
import { ReactComponent as Connect } from '../components/svg/connect.svg';

import Modal from 'react-modal';

import firebase from 'firebase/app';
import 'firebase/storage'

import { tokenDB, thumbnailDB } from "../App";

import Background from "../components/background";

import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const utilities = require("../components/utilities");


const MyCypherdudes = () => {
    const alchemy = new Alchemy({
        apiKey : process.env.ALCHEMY_ETH_KEY,
        network: Network.ETH_MAINNET,
      });

    const [supply, setSupply] = useState(0);
    const [mintStatus, setMintStatus] = useState(false);
    const [txStatus, setTxStatus] = useState('idle');
    const [tokens, setTokens] = useState([]);
    const [balance, setBalance] = useState(0);
    const [bitBalance, setBitBalance] = useState(0);
    const [txError, setTxError] = useState(undefined);
    const [loadStatus, setLoadStatus] = useState(false);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesBitReadContract = new ethers.Contract(
        contractAddresses.CypherDudesBit,
        CypherDudesBitArtifact.abi,
        LlamaProvider_
    );

    const cypherPalacesContract = new ethers.Contract(
        contractAddresses.CypherPalaces,
        CypherPalacesArtifact.abi,
        LlamaProvider_
    );

    const [modalIsOpen, setIsOpen] = useState(false);
    let subtitle;
    const customStyles = {
        overlay: {
            position: 'fixed',
            inset: '0px',
            backgroundColor: '#000000B3'
        },
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            border: '1px solid #00ff00',
            background: '#000',
            borderRadius: '0px',
            transform: 'translate(-50%, -50%)',
        },
    };

    useEffect(() => {
        _getTokensOfOwner();
        _updateSupply();
        getMintStatus();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            _getTokensOfOwner()
        }, 1000);
    }, [address]);

    useEffect(() => {
        if (address !== undefined) {
            const interval = setInterval(() => {
                _updateSupply();
                getMintStatus();
            }, 10000);
            _updateSupply();
            getMintStatus();
            return () => clearInterval(interval);
        }
    }, [address]);

    useEffect(() => {
        if (loadStatus) {
            setTimeout(() => {
                //saveThumbnail();
            }, 1000);
        }
    }, [loadStatus]);

    useEffect(() => {
        var status = document.getElementById('status')
        if (status != null) {
            var statusBtn = document.getElementById('statusBtn')
            status.innerHTML = txStatus;
            statusBtn.style.display = 'block';
        }
    }, [txStatus]);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#00ff00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    const queryOptions = {
        contractAddresses: [contractAddresses.CypherDudes],
        omitMetadata: true,
    }
    const bitQueryOptions = {
        contractAddresses: [contractAddresses.CypherDudesBit],
        omitMetadata: true,
    }

    const _getTokensIdfromOwner = async () => {
        const tokens = await alchemy.nft.getNftsForOwner(address, queryOptions);
        return tokens.ownedNfts;
    }

    const _getBitTokensIdfromOwner = async () => {
        const tokens = await alchemy.nft.getNftsForOwner(address, bitQueryOptions);
        return tokens.ownedNfts;
    }

    const _getTokensOfOwner = async () => {
        if (!isConnected) {
            return;
        }
        try {
            const ownedTokens = await _getTokensIdfromOwner();
            const ownedBitTokens = await _getBitTokensIdfromOwner();
            const cydBalance = await cypherDudesReadContract.balanceOf(address);
            const cydBitBalance = await cypherDudesBitReadContract.balanceOf(address);
            setBalance(cydBalance);
            setBitBalance(cydBitBalance);
            setTokens([]);

            let fetchedTokens = [];
            for (let i = 0; i < ownedTokens.length; i++) {
                try {
                    let tokenURI = await cypherDudesReadContract.tokenURI(ownedTokens[i].tokenId);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    const palaceID = await cypherPalacesContract.palaceFromCyd(ownedTokens[i].tokenId);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: ownedTokens[i].tokenId.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL,
                        palaceId: palaceID
                    }
                    fetchedTokens.push(tokenEntry);
                    setTokens([...fetchedTokens]);
                } catch (error) {
                    console.log(error)
                }
            }
            for (let i = 0; i < ownedBitTokens.length; i++) {
                try {
                    let tokenURI = await cypherDudesBitReadContract.tokenURI(ownedBitTokens[i].tokenId);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    const palaceID = await cypherPalacesContract.palaceFromCyd(ownedBitTokens[i].tokenId);
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: ownedBitTokens[i].tokenId.toString(),
                        name: tokenURIJSONDecoded.name,
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL,
                        palaceId: palaceID
                    }
                    fetchedTokens.push(tokenEntry);
                    setTokens([...fetchedTokens]);
                } catch (error) {
                    console.log(error)
                }
            }
            setLoadStatus(true);
        } catch (error) {
            console.log(error);
            setTxError(error);
        }
    }

    const _updateSupply = async () => {
        const _supply = await cypherPalacesContract.totalSupply();
        setSupply(Number(_supply));
    }

    const getMintStatus = async () => {
        const _mintStatus = await cypherPalacesContract.claimStarted();
        setMintStatus(_mintStatus);
    }

    const _dismissTransactionError = () => {
        setTxError(undefined);
    }

    const claimPalace = async (tokenId) =>{
        console.log(`Claiming Palace for CYPHERDUDE #${tokenId}`);
        if (!isConnected) {
            alert('connect your wallet');
            return;
        }
        else if(chainId!=1){
            alert('switch to ethereum mainnet');
            return;
        }
        else {
                try {
                    openModal();
                    setTxStatus('Mint intiated')
                    const signer = await provider.getSigner();
                    const cypherPalacesContract = new ethers.Contract(
                        contractAddresses.CypherPalaces,
                        CypherPalacesArtifact.abi,
                        signer
                    );
                    _dismissTransactionError();
                    const cost = balance+bitBalance < 3 ? 0.0512 : 0.0420;
                    console.log(ethers.parseEther((cost).toFixed(6).toString()));
                    const tx = await cypherPalacesContract.claimPalace(tokenId, { value: ethers.parseEther((cost).toFixed(6).toString()) });
                    const receipt = await tx.wait();
                    if (receipt.status === 0) {
                        setTxStatus('transaction failed');
                        throw new Error("transaction failed");
                    }
                    setTxStatus('Mint sucessfull');
                    await _updateSupply();
                } catch (error) {
    
                    if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                        setTxStatus('Transaction canceled by the user');
                        return;
                    }
                    const decodedError = cypherPalacesContract.interface.parseError(error.data)
                    setTxStatus('Transaction failed : ' + decodedError?.args)
                    console.log(error);
                    setTxError(error);
                } finally {
                }
        }
    }

    document.addEventListener("mousemove", function (event) {
        if(document.getElementById("desktopMenu") != null){
            const nav = document.getElementById("navMenu");
            nav.style.transform = "translateY(0)";
            nav.style.transition = "transform 0.3s ease-in-out";
        }
    })

    return (
        <div>
            <Background />
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                style={customStyles}
                contentLabel="Inscription Complete">
                <h2 id="status" ref={(_subtitle) => (subtitle = _subtitle)}>Mint initiated...</h2>
                <button id="statusBtn" className="uxBtn modal" onClick={closeModal}>CLOSE</button>
            </Modal>
            <div className="tokenContainer">
            {   
                    !mintStatus ?
                    <div className="highlightInfo"><p>CYPHERPALACES mint will start February 13th 2025 at 2pm UTC</p><p>Price : 0.0512 each - 0.0420 for holders of 3 Cypherdudes</p><p>Minting a Cypherpalace will permanently attach it to the selected Cypherdude (it's a Soulbound token that will be minted to the token bound wallet of your cypherdudes).</p><p>CLAIMING A WORD UNLOCKS THE MINT</p></div>
                    :
                    supply < 512 ?
                        <div className="highlightInfo"><p>CYPHERPALACES MINT IS LIVE ! {supply}/512 minted</p><p>Price : 0.0512 each - 0.0420 for holders of 3 Cypherdudes</p><p>Minting a Cypherpalace will permanently attach it to the selected Cypherdude (it's a Soulbound token that will be minted to the token bound wallet of your cypherdudes).</p><p>CLAIMING A WORD UNLOCKS THE MINT</p></div>: <p>CYPHERPALACES HAS MINTED OUT !</p>
                }
                {isConnected ? tokens.length < 1 ?
                    <div>
                        <p className="highlight">Fetching your Cypherdudes from the blockchain, This can take a while...</p>
                        <div className="loader">
                            <Loader />
                        </div>
                    </div>
                    :
                    <div>
                        <p className="highlight">{`You own ${balance+bitBalance} cypherdude${(balance+bitBalance) < 2 ? '' : 's'}`}</p>
                        {
                            tokens.map((token, id) => {
                                return <LiveTokenCard tokens={token} key={id} mint={claimPalace}/>
                            })
                        }
                    </div>
                    :
                    <div>
                        <p className="highlight">Connect your wallet to browse your Cypherdudes</p>
                        <div className="loader">
                            <Connect />
                        </div>
                    </div>
                }

            </div>
        </div>
    )
}

export default MyCypherdudes;