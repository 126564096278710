import React from "react";
import { useEffect, useState } from "react";
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import CypherDudesArtifact from '../web3/abi/CypherDudes.json';
import CypherDudesBitArtifact from '../web3/abi/CypherDudesBit.json';
import CypherDudesArchivesArtifact from '../web3/abi/CypherDudesArchives.json';
import { contractAddresses } from '../web3/contractsAddresses';
import { ethers } from "ethers";
import Modal from 'react-modal';
import { GiChart } from "react-icons/gi";
import { FaFolderOpen } from "react-icons/fa6";
import { v4 as uuidv4 } from 'uuid';
import { BsLightningFill } from "react-icons/bs";
import { AiOutlineCaretLeft, AiOutlineCaretRight } from "react-icons/ai";
import ChartRenderer from "../components/chartRenderer";
import { createWeb3Modal, defaultConfig, useWeb3Modal, useWeb3ModalAccount, useWeb3ModalProvider } from "@web3modal/ethers/react";

import Background from "../components/background";

const utilities = require("../components/utilities");
const ERROR_CODE_TX_REJECTED_BY_USER = 4001;

const OwnerLink = styled(Link)`
margin-bottom :10px;
&:focus, &:hover, &:visited, &:link, &:active {
    color:#fff;
}
&:hover{
    color:#00ff00;
}
`;

const Archive = ({ tokenID }) => {

    const params = useParams();
    const id = params.tokenId;

    const [tokenURI, setTokenURI] = useState({});
    const [tokenName, setTokenName] = useState('');
    const [decodeTicker, setDecodeTicker] = useState(false);
    const [archiveData, setArchiveData] = useState({});
    const [currentOwner, setCurrentOwner] = useState({});
    const [archiveSupply, setArchiveSupply] = useState(0);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider()
    const provider = new ethers.BrowserProvider(walletProvider);
    const infuraProvider = new ethers.JsonRpcProvider("https://mainnet.infura.io/v3/a401f99315474f15bf32bb81404d0886");
    const LlamaProvider_ = new ethers.JsonRpcProvider("https://eth.llamarpc.com/sk_llama_e95422cce70e51a648a78b78dc29799f");
    const openProvider = new ethers.BrowserProvider(window.ethereum);

    const cypherDudesReadContract = new ethers.Contract(
        contractAddresses.CypherDudes,
        CypherDudesArtifact.abi,
        LlamaProvider_
    );

    const cypherDudesArchivesReadContract = new ethers.Contract(
        contractAddresses.CypherDudesArchives,
        CypherDudesArchivesArtifact.abi,
        LlamaProvider_
    );

    useEffect(() => {
        setTimeout(() => {
            getCurrentOwner(id);
            _getTokenURI();
            getArchiveSupply();
        }, 500);

    }, []);

    useEffect(() => {
        setTimeout(() => {
            getCurrentOwner(id);
            _getTokenURI();
            getArchiveSupply();
        }, 500);

    }, [id]);

    useEffect(() => {
        if (decodeTicker) {
            decode();
        }
        setDecodeTicker(false)
    }, [decodeTicker])

    const getValueByTraitType = (jsonData, traitType) => {
        const trait = jsonData.find(trait => trait.trait_type === traitType)?.value || '';
        return trait;
    }

    const getArchiveSupply = async () => {
        let supply = await cypherDudesArchivesReadContract.totalSupply();
        setArchiveSupply(Number(supply));
    }

    const getAddress = async (address) => {
        let resolvedAddress = await LlamaProvider_.lookupAddress(address);
        const holder = {
            longAddress: address,
            shortAddress: resolvedAddress === null ? address.slice(0, 6) + "..." + address.slice(-4) : resolvedAddress

        }
        return holder
    }

    document.addEventListener("mousemove", function (event) {
        if(document.getElementById("desktopMenu") != null){
            const nav = document.getElementById("navMenu");
            nav.style.transform = "translateY(0)";
            nav.style.transition = "transform 0.3s ease-in-out";
        }
    })

    const getCurrentOwner = async (id) => {
        try {
            let owner = await cypherDudesArchivesReadContract.ownerOf(id);
            setCurrentOwner(await getAddress(owner));
        } catch (error) {
            console.log(error);
        }
    }

    const _getTokenURI = async () => {
        if (!isConnected) {
            setTokenURI({});
            try {
                setTokenURI({});
                try {
                    let tokenURI = await cypherDudesArchivesReadContract.tokenURI(id);
                    let archive = await cypherDudesArchivesReadContract.archiveData(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    let dudeName = getValueByTraitType(tokenURIJSONDecoded.attributes, "Issuer");
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: id.toString(),
                        dudeId: parseInt(dudeName.split('#')[1]),
                        name: tokenURIJSONDecoded.name,
                        timeStamp: Number(archive[4]),
                        name: archiveData[6],
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry)
                    setTokenName(tokenURIJSONDecoded.name);
                    setArchiveData(archive);
                } catch (error) {
                    console.log(error)
                }
            } catch (error) {
                console.log(error);
            } finally {
            }
        } else {
            try {
                setTokenURI({});
                const signer = await provider.getSigner();
                const cypherDudesArchivesContract = new ethers.Contract(
                    contractAddresses.CypherDudesArchives,
                    CypherDudesArchivesArtifact.abi,
                    signer
                );
                try {
                    let tokenURI = await cypherDudesArchivesContract.tokenURI(id);
                    let archive = await cypherDudesArchivesReadContract.archiveData(id);
                    const tokenURIDecoded = utilities.parseBase64DataURI(tokenURI);
                    const tokenURIJSONDecoded = JSON.parse(tokenURIDecoded);
                    const animationURL = utilities.parseBase64DataURI(tokenURIJSONDecoded.animation_url);
                    let dudeName = getValueByTraitType(tokenURIJSONDecoded.attributes, "Issuer");
                    const tokenEntry = {
                        id: uuidv4(),
                        tokenId: id.toString(),
                        dudeId: parseInt(dudeName.split('#')[1]),
                        name: tokenURIJSONDecoded.name,
                        timeStamp: Number(archive[4]),
                        name: archiveData[6],
                        traits: tokenURIJSONDecoded.attributes,
                        animationURL: animationURL
                    }
                    setTokenURI(tokenEntry)
                    setTokenName(tokenURIJSONDecoded.name);
                    setArchiveData(archive);
                } catch (error) {
                    console.log(error)
                }
            } catch (error) {
                console.log(error);
            } finally {
            }
        }
    }

    function decode() {
        console.log('decode ready')
        if (archiveData.freeAccess) {
            let pass = archiveData.secret;
            console.log(pass);
            decrypt(pass)
        } else {
            var iframe = document.getElementById('tokenIframe');
            var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            var pass = iframeDocument.getElementById('dKey').value;
            decrypt(pass);
        }
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var _body = iframeDocument.getElementById('svg-container').parentElement;
        var _html = iframeDocument.getElementById('svg-container').parentElement.parentElement;
        _body.style.setProperty('display', 'block', 'important');
        _html.style.setProperty('display', 'block', 'important');
    }

    const decrypt = async (key) => {
        try {
            var iframe = document.getElementById('tokenIframe');
            var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
            var hash = iframeDocument.getElementById('decodedData').textContent;
            hash = hash.replace(/^\s+|\s+$/g, '');
            var bytes = ethers.hexlify(hash);
            const message = await cypherDudesReadContract.decrypt(key, hash);

            iframeDocument.getElementById('decodedData').innerHTML = message;
        } catch (error) {
            if (error.code === ERROR_CODE_TX_REJECTED_BY_USER) {
                return;
            }
            console.log(error);
        }
    }

    window.addEventListener('decode', () => {
        setDecodeTicker(true)
    })

    const reloadScripts = () => {
        var iframe = document.getElementById('tokenIframe');
        var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
        var container = iframeDocument.getElementById('decodedData');
        let scriptList = container.getElementsByTagName('script');
        var newScripts = [];
        for (let i = 0; i < scriptList.length; i++) {
            let newScript = document.createElement('script');
            newScript.textContent = scriptList[i].textContent;
            scriptList[i].remove();
            newScripts.push(newScript);
        }
        newScripts.forEach((script) => container.lastChild.after(script))
    }

    window.addEventListener("keydown", function (e) {
        if (["Space", "ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"].indexOf(e.code) > -1) {
            e.preventDefault();
        }
    }, false);

    return (
        <div>
            <Background />
            <div className='previewContainer'>
                <div className='widePreview'>
                    <iframe id="tokenIframe" sandbox="allow-scripts allow-same-origin allow-popups" frameBorder="0" srcDoc={tokenURI.animationURL}></iframe>
                </div>
                <div className='claimWordTokenInfo'>
                    <p>{tokenName}</p>
                    <div className="TokenBtns">
                        <Link to={`/archive/${id === '0' ? (archiveSupply-1).toString() : (parseInt(id) - 1).toString()}`} className='chartBtn'><AiOutlineCaretLeft /></Link>
                        <div>
                            <button onClick={() => reloadScripts()} className='chartBtn'><BsLightningFill /></button>
                            <Link to={`/folder/${tokenURI.dudeId}`} className='chartBtn'><FaFolderOpen /></Link>
                        </div>
                        <Link to={`/archive/${id === (archiveSupply-1).toString() ? '0' : (parseInt(id) + 1).toString()}`} className='chartBtn'><AiOutlineCaretRight /></Link>
                    </div>
                    <div className="footerInfo">
                        <p id="issued" className='sideInfo'>{`Issued : ${tokenURI.timeStamp === null ? '' : new Date(parseInt(tokenURI.timeStamp) * 1000).toUTCString()}`}</p>
                        <p id="owned" className='sideInfo'>Owned by : <OwnerLink target="_blank" to={`https://zapper.xyz/account/${currentOwner.longAddress}`}>{currentOwner.shortAddress}</OwnerLink></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Archive;