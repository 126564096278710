export const contractAddresses = {
    CypherDudes: "0x9D0f5277D19075eC1201fF94a483c5bCEe718d20",
    CypherDudesRender : "0xCaD8db7b347e26f3aCFC9F22BEaE4e445389766b",
    ContentStore : "0x01044883Ed596a50e211ab2EAFFd9a43Ad382C8c",
    FileStore : "0x9181580FCe530e5FBD8DbA7141Fc0cCBb8e59b8B",
    CypherDudesArchives:"0xe1851e0D09352f2E56171c62c7DEd015e199258B",
    CypherDudesBit: "0x13d3Cc46a839Ab3Ba3b527C5c9d98fdA724432f6",
    CypherDudesBitRender : "0xcf5f4212E8bC673936EfE3EE60962fb8D3Db8Cc6",
    CypherPalacesRenderer: "0x0DE7f66f999b3E32070025113Aa7459fff9Bd8D6",
    CypherPalaces: "0xDBdeec1D41C377B8C0277AE2b7D76b94c48aceF8"
}